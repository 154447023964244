@use 'base';

.done {
  &-section {
    &-stories {
      &-bottom {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &-page {
    &-stories {
      &-slider {
        width: 100vw;
        position: relative;
        left: 0;
        overflow: hidden;
        display: flex;
        height: 28rem;

        &-slides {
          display: flex;
          flex-direction: row;
          width: 100vw;
          position: relative;
        }
      }
      &-slide {
        width: 100vw;
        padding: 2rem;
        flex-shrink: 0;
        height: 28rem;
      }

      &-pagination {
        text-align: center;
        display: flex;
        flex-direction: row;
        margin-right: 0;
        margin-top: 1rem;
        height: 100%;
        justify-content: center;
        gap: 2rem;

        &-bullet-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
        }

        &-line {
          width: 100%;
          height: 2px;
          background-color: var(--done-color-333834);

          &.active {
            background-color: var(--done-color-00ff89);
          }
        }

        &-bullet {
          display: inline-block;
          min-width: 2.4rem;
          min-height: 2.4rem;
          max-width: 2.4rem;
          max-height: 2.4rem;
          cursor: pointer;
          border: solid 2px var(--done-color-eaeaf6);

          .bullet-icon {
            width: 1.6rem;
            height: 1.6rem;
            color: var(--done-color-ffffff);
            display: none;
          }

          &.active,
          &:focus {
            background-color: var(--done-color-eaeaf6);
          }
        }
      }
    }
  }
}

.btn-mobile {
  display: flex;
}

.btn-desktop {
  display: none;
}

@media only screen and (min-width: base.$large-devices) {
  .btn-mobile {
    display: none;
  }
  .btn-desktop {
    display: flex;
    width: 19rem;
  }
  .done {
    &-section {
      &-stories {
        color: var(--done-color-000000);

        &-title {
          color: var(--done-color-000000);
        }

        &-bottom {
          flex-direction: row;
          justify-content: space-between;
        }

        &-arrow {
          color: var(--done-color-000000);
        }
      }
    }
    &-page {
      &-stories {
        &-slider {
          width: 32rem;

          &-slides {
            flex-direction: column;
          }
        }

        &-slide {
          width: 100%;
          padding: 0;
        }

        &-pagination {
          flex-direction: column;
          margin-right: 4.8rem;
          margin-top: 0;
          gap: 0;

          &-bullet-container {
            flex-direction: column;
            height: 20%;
          }

          &-line {
            width: 2px;
            height: 100%;
          }

          &-bullet {
            border: solid 4px;
            border-color: var(--done-color-eaeaf6);
            background-color: var(--done-color-333834);
            display: flex;
            justify-content: center;
            align-items: center;

            .bullet-icon {
              color: var(--done-color-ffffff);
              display: none;
            }

            &.active {
              background-color: var(--done-color-00ff89);
              border-color: var(--done-color-00ff89);

              .bullet-icon {
                display: block;
              }
              &:hover {
                background-color: var(--done-color-333834);
              }
            }

            &.completed {
              background-color: var(--done-color-00ff89);
              border-color: var(--done-color-00ff89);
            }

            &:hover {
              background-color: var(--done-color-333834);
              border: solid 2px var(--done-color-00ff89);
            }
          }
        }
      }
    }
  }
}
