@font-face {
  font-family: PP Neue Montreal;
  src: url("PPNeueMontreal-Regular.5e0546b4.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url("Neue-Montreal-Medium.0edc0724.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url("Neue-Montreal-Bold.836b8798.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Signal Mono;
  src: url("Signal Mono-Regular-Web.b0c13c96.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

.done-section-stories-bottom {
  flex-direction: column;
  display: flex;
}

.done-page-stories-slider {
  width: 100vw;
  height: 28rem;
  display: flex;
  position: relative;
  left: 0;
  overflow: hidden;
}

.done-page-stories-slider-slides {
  flex-direction: row;
  width: 100vw;
  display: flex;
  position: relative;
}

.done-page-stories-slide {
  flex-shrink: 0;
  width: 100vw;
  height: 28rem;
  padding: 2rem;
}

.done-page-stories-pagination {
  text-align: center;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  height: 100%;
  margin-top: 1rem;
  margin-right: 0;
  display: flex;
}

.done-page-stories-pagination-bullet-container {
  flex-direction: row;
  align-items: center;
  display: flex;
  position: relative;
}

.done-page-stories-pagination-line {
  background-color: var(--done-color-333834);
  width: 100%;
  height: 2px;
}

.done-page-stories-pagination-line.active {
  background-color: var(--done-color-00ff89);
}

.done-page-stories-pagination-bullet {
  cursor: pointer;
  border: solid 2px var(--done-color-eaeaf6);
  min-width: 2.4rem;
  max-width: 2.4rem;
  min-height: 2.4rem;
  max-height: 2.4rem;
  display: inline-block;
}

.done-page-stories-pagination-bullet .bullet-icon {
  color: var(--done-color-ffffff);
  width: 1.6rem;
  height: 1.6rem;
  display: none;
}

.done-page-stories-pagination-bullet.active, .done-page-stories-pagination-bullet:focus {
  background-color: var(--done-color-eaeaf6);
}

.btn-mobile {
  display: flex;
}

.btn-desktop {
  display: none;
}

@media only screen and (width >= 992px) {
  .btn-mobile {
    display: none;
  }

  .btn-desktop {
    width: 19rem;
    display: flex;
  }

  .done-section-stories, .done-section-stories-title {
    color: var(--done-color-000000);
  }

  .done-section-stories-bottom {
    flex-direction: row;
    justify-content: space-between;
  }

  .done-section-stories-arrow {
    color: var(--done-color-000000);
  }

  .done-page-stories-slider {
    width: 32rem;
  }

  .done-page-stories-slider-slides {
    flex-direction: column;
  }

  .done-page-stories-slide {
    width: 100%;
    padding: 0;
  }

  .done-page-stories-pagination {
    flex-direction: column;
    gap: 0;
    margin-top: 0;
    margin-right: 4.8rem;
  }

  .done-page-stories-pagination-bullet-container {
    flex-direction: column;
    height: 20%;
  }

  .done-page-stories-pagination-line {
    width: 2px;
    height: 100%;
  }

  .done-page-stories-pagination-bullet {
    border: 4px solid;
    border-color: var(--done-color-eaeaf6);
    background-color: var(--done-color-333834);
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .done-page-stories-pagination-bullet .bullet-icon {
    color: var(--done-color-ffffff);
    display: none;
  }

  .done-page-stories-pagination-bullet.active {
    background-color: var(--done-color-00ff89);
    border-color: var(--done-color-00ff89);
  }

  .done-page-stories-pagination-bullet.active .bullet-icon {
    display: block;
  }

  .done-page-stories-pagination-bullet.active:hover {
    background-color: var(--done-color-333834);
  }

  .done-page-stories-pagination-bullet.completed {
    background-color: var(--done-color-00ff89);
    border-color: var(--done-color-00ff89);
  }

  .done-page-stories-pagination-bullet:hover {
    background-color: var(--done-color-333834);
    border: solid 2px var(--done-color-00ff89);
  }
}

/*# sourceMappingURL=index.928270b9.css.map */
